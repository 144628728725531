import { abreviarNome } from 'libs/StringUteis';
import { StatusID } from 'pages/coleta/listar/constants/status-name-2-id';
import { useCallback, useContext } from 'react';
import { AuthContext } from 'routes/auth.context';
import { IColeta } from '../index.d';
import { calculaValorFreteComTaxa, formatarMoeda } from '../../../../../../libs/NumberUtils';
import { IConfiguracao } from '../../../../../../types/modules/configuracao';
import { IUnidade } from '../../../../../../types/modules/unidade';
interface IInput {
    administrador: boolean;
}
export const useEntregador = (permissoes?: IInput) => {
    const { user } = useContext(AuthContext);

    const isAdmin = user?.perfil?.id === 1;
    const viewButtonWhatsapp = useCallback((coleta: IColeta) => {
        let viewButtonWhatsapp = false;
        const [atual] =
                coleta?.status && coleta?.status.length > 0
                    ? coleta?.status
                    : [{}];
        // cliente
        if(!permissoes.administrador){
            const mostraBtnWhatsapp = [
                StatusID.ACEITO,
                StatusID.CHECKIN_UNIDADE,
                StatusID.CHECKOUT_UNIDADE,
                StatusID.CHECKIN_CLIENTE,
                StatusID.RETORNO_ESTABELECIMENTO,
            ].includes(atual.id);
            if(mostraBtnWhatsapp){
                viewButtonWhatsapp = true;
            }
        } else if (permissoes.administrador) {
            // admin
            viewButtonWhatsapp = true;
        }
        return viewButtonWhatsapp;
    }, [permissoes]);
    const viewButtonAcompanharEntregador = useCallback((coleta: IColeta) => {
        let viewButtonWhatsapp = false;
        const [atual] =
                coleta?.status && coleta?.status.length > 0
                    ? coleta?.status
                    : [{}];
        // cliente
        if(!permissoes.administrador){
            const mostraBtnWhatsapp = [
                StatusID.CHECKOUT_UNIDADE,
                StatusID.CHECKIN_CLIENTE,
                StatusID.RETORNO_ESTABELECIMENTO,
            ].includes(atual.id);
            if(mostraBtnWhatsapp){
                viewButtonWhatsapp = true;
            }
        } else if (permissoes.administrador) {
            // admin
            viewButtonWhatsapp = true;
        }
        return viewButtonWhatsapp;
    }, [permissoes]);
    const handleTransformEntregador = useCallback((coleta?: IColeta, configuracao?: IConfiguracao[], unidadeSelecionada?: IUnidade) => {
        const distanciaMinima = coleta?.kmDistanciaMinima >= 0 ? `${coleta?.kmDistanciaMinima}` : configuracao?.find(
            (config) => config.chave === "Frete::DistanciaMinima::Km"
        )?.valor;
        const valorAdicionalCorrida = coleta?.adicionalEntregador >= 0 ? `${coleta?.adicionalEntregador}` : configuracao.find(
            ({ chave }) => chave === 'Valor::Adicional::Corrida::Entregador',
        )?.valor;
        const valorAdicionalCorridaJogoRapido = coleta?.adicionalJogoRapido >= 0 ? `${coleta?.adicionalJogoRapido}` : configuracao.find(
            ({ chave }) => chave === 'Valor::Adicional::Corrida',
        )?.valor;
        const { qtdxchamouplayer, id: statusId } =
            coleta?.status && coleta?.status.length > 0
                ? coleta?.status[0]
                : { qtdxchamouplayer: 0, id: 8 };
        // const chamadas = statusId === 1 ? ` ( ${qtdxchamouplayer}x )` : '';
        let frete = coleta?.valorFrete;

        const valorComRetornoESem = calculaValorFreteComTaxa({
            frete: coleta?.valorFrete,
            porcentagemNegociacao: coleta?.unidade?.porcentagemNegociacao,
            txMinRetorno: coleta?.taxaMinimaRetornoMoto ? coleta?.taxaMinimaRetornoMoto : coleta?.modalTransporte?.txMinRetorno,
            distanciaMinima: parseInt(distanciaMinima),
            adcionalRetorno: coleta?.kmAdicionalRetornoMoto ? coleta?.kmAdicionalRetornoMoto : coleta?.modalTransporte?.valorKmRetorno,
            distancia: (coleta?.metricaDistUnidade + coleta?.metricaPrecisao),
            mostrarValorTotal: true,
            valorAdicionalCorridaJogoRapido: valorAdicionalCorridaJogoRapido
                ? Number(valorAdicionalCorridaJogoRapido)
                : 0,
        });
        if(coleta.pagamento.exigeTaxaRetorno){
            frete = valorComRetornoESem.valorFreteComRetorno;
        }else{
            frete = valorComRetornoESem.valorFreteSemRetorno;
        }
        const chamadas = '';
        return {
            status: 'ativo',
            tipo: 'Texto',
            width: 'minmax(24rem, 1fr)',
            marginRight: '0',

            ...((isAdmin || (
                statusId === StatusID.ACEITO ||
                statusId === StatusID.CHECKIN_UNIDADE ||
                statusId === StatusID.CHECKOUT_UNIDADE ||
                statusId === StatusID.CHECKIN_CLIENTE ||
                statusId === StatusID.CONCLUIDO ||
                statusId === StatusID.CANCELADO ||
                statusId === StatusID.RETORNO_ESTABELECIMENTO
            ))
                ? {
                      titulo: `${abreviarNome(
                          coleta?.entregador?.nomeCompleto
                      )}${isAdmin ? chamadas : ''}`,
                      subtitulo: coleta?.entregador?.telefone,
                      descricao: `Jogo Rápido ( ${formatarMoeda(frete)} )`,
                  }
                : {
                      titulo:
                          statusId === StatusID.PENDENTE ? `procurando player` : '',
                      descricao: `Jogo Rápido ( ${formatarMoeda(frete)} )`,
                  }),
            flexDirection: 'row',
            botoesInline: [...viewButtonWhatsapp(coleta) ? [
                {
                    titulo: `Whatsapp entregador`,
                    icone: '/whatsapp.png',
                    click: () => {
                        window.open(`https://wa.me/+55${coleta.entregador.telefone.replace(/\D/g, "")}`, '_blank');
                    },
                },
            ] : [], ...viewButtonAcompanharEntregador(coleta) ? [{
                titulo: `Link Acompanhar Entregador`,
                icone: '/pin-red.png',
                click: () => {
                    navigator.clipboard.writeText(`https://painel.jogorapidodelivery.com.br/acompanhar-entregador/${coleta.id}`).then(function() {
                        alert('Link copiado');
                      }, function(err) {
                        alert('Erro ao copiar link');
                      });
                },
            }] : []],
        };
    }, []);
    return { handleTransformEntregador };
};
