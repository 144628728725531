import { abreviarNome, appendPrependString } from 'libs/StringUteis';
import { useCallback } from 'react';
import { IColeta } from '../index.d';

export const useCliente = () => {

    const handleTransformCliente = useCallback((coleta: IColeta) => {
        let descricao2 = coleta?.pagamento.formaPagamentoNome;
        if (coleta?.pagamento.formaPagamentoId === 1) {
            descricao2 = `troco R$ ${(
                coleta?.pagamento?.valorTroco || 0
            ).toFixed(2)}`;
        }
        return {
            status: 'ativo',
            tipo: 'Texto',
            width: 'minmax(20rem, 1fr)',
            marginRight: '0',
            titulo: abreviarNome(coleta?.cliente?.nomeCompleto),
            subtitulo: `${coleta?.display_number_ifood ? `#${coleta?.display_number_ifood} | ` : ''}${appendPrependString(
                coleta?.cliente?.telefoneCelular ||
                    coleta?.cliente?.telefoneFixo,
                ''
            )}${coleta?.localizer_number_ifood ? ` - ${coleta?.localizer_number_ifood}` : ''}`,
            descricao: `R$ ${(coleta?.valorTotalProdutos || 0).toFixed(
                2
            )}`,
            descricao2,
            flexDirection: 'row',
            botoesInline: [...(coleta?.pedido_ifood_id ? [
                {
                    titulo: `Está coleta foi feita pelo iFood`,
                    icone: '/icone-ifood.png',
                    click: () => {
                        
                    },
                },
            ] : (
                coleta?.pedido_anotaai_id ? [
                    {
                        titulo: `Está coleta foi feita pelo Anota Aí`,
                        icone: '/anotaai.png',
                        click: () => {
                            
                        },
                    },
                ] : [
                    {
                        titulo: `Está coleta foi feita pela plataforma da jogo rápido`,
                        icone: '/logo512.png',
                        click: () => {
                            
                        },
                    }
                ]
            ))],
        };
    }, []);
    return { handleTransformCliente };
};
